<template>
  <main
    class="tourism color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  > 
    <!-- <TourismFilter :hasTypeDeal="false"></TourismFilter> -->
    <BannerSection location="homepage-top"></BannerSection>
    <div class="d-flex justify-content-between align-items-center mx10">
      <h4>Flights</h4>
      <div class="d-flex m-t-15 mobile-filter-buttons m-b-10">
        <button 
          class="primary-btn d-flex align-items-center mx5"
          @click="openFilterModal"
        >
          {{ $t('REPORT_SALES.SEARCH') }}
          <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
        </button>
      </div>
    </div>
    <MobileFilterModal :isFilterModalVisible.sync="isFilterModalVisible"></MobileFilterModal>
    <div class="tourism-container m-t-10">
      <h4 class="mx10 p5">
        Cheapest flights
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px10" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openMobileDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'MobileFlightAll',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
      <h4 class="text-center p5">
        View all Cheapest Flights
      </h4>
    </div>
    <div class="tourism-container m-t-30">
      <h4 class="mx10 p5">
        <p>Last minute flights</p>
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px10" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openMobileDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'MobileFlightAll',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
      <h4 class="text-center p5">
        View all last minutes flights
      </h4>
    </div>
    <MobileDetailModal :isOrder="false"></MobileDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../../components/BannerSection";
import TourismFilter from "../../../../components/tourism/TourismFilter.vue";
import FlightTourismItem from "../../../../components/tourism/flight/FlightTourismItem.vue";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import MobileFilterModal from "../../../../components/tourism/flight/MobileFilterModal.vue";

export default {
  name: "MobileFligntHome",
  components: {
    BannerSection,
    TourismFilter,
    FlightTourismItem,
    MobileDetailModal,
    MobileFilterModal
  },
  data() {
    return {
      deals: [{},{},{},{},{},{},{},{},{},{},{},{}],
      isFilterModalVisible: false
    }
  },
  methods: {
    openMobileDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";
</style>
